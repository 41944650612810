import React, { useState }                                                  from 'react';
import { Button, Form }                                                     from "react-bootstrap";
import { useTranslation }                                                   from "react-i18next";
import { useDispatch }                                                      from "react-redux";
import { useNavigate }                                                      from "react-router-dom";

import InputEmail                                                           from "../input/InputEmail";
import InputPassword                                                        from "../input/InputPassword";
import LinkOnLoginPage                                                      from "../shared/LinkOnLoginPage";
import { DEFAULT_ERROR_OBJECT, EMAIL, PASSWORD, routesNames }               from "../../constants";
import { login }                                                            from "../../utils/api";
import { checkValidate, serializeFormToObject }                             from "../../utils/misc";
import { emailValidate, noEmptyValidate, passwordValidate }                 from "../../utils/validate";
import { setAlert, setLoadSpinner, setTokenInfo }                           from "../../storage/global";

const LoginForm = () => {

    const [errorEmail, setErrorEmail] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPassword, setErrorPassword] = useState({...DEFAULT_ERROR_OBJECT});

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validations = {}
    validations[EMAIL] = [noEmptyValidate(t('validEmpty')), emailValidate(t('validEmail'))]
    validations[PASSWORD] = [noEmptyValidate(t('validEmpty')), passwordValidate(t('validPassword'))]

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        const data = serializeFormToObject(form);

        const errors = [
            checkValidate(data[EMAIL], validations[EMAIL], setErrorEmail),
            checkValidate(data[PASSWORD], validations[PASSWORD], setErrorPassword),
        ]
        const isError = errors.filter(er => er.isError).length
        if (!isError) {
            dispatch(setLoadSpinner(true))
            login(data)
                .then(response => {
                    navigate(`/${routesNames.NEW_PARCELS}`)
                })
                .catch(({response}) => {
                    dispatch(setAlert({
                        title: t('message'),
                        text: response?.statusText || '',
                        type: 'danger'
                    }))
                })
                .finally(() => {
                    dispatch(setLoadSpinner(false))
                })
        }
    }



    function handleValues(value, name) {
        if (name === EMAIL) {
            checkValidate(value, validations[name], setErrorEmail)
        } else if (name === PASSWORD) {
            checkValidate(value, validations[name], setErrorPassword)
        }
    }

    return (
        <Form noValidate onSubmit={handleSubmit}>
            <InputEmail
                id={EMAIL}
                type={EMAIL}
                classes={''}
                errorMessage={errorEmail.message}
                validations={validations[EMAIL]}
                handleChange={handleValues}
                handleBlur={handleValues}
                closeButton
            />
            <InputPassword
                id={PASSWORD}
                type={PASSWORD}
                classes={'mt-4'}
                errorMessage={errorPassword.message}
                validations={validations[PASSWORD]}
                handleChange={handleValues}
                handleBlur={handleValues}
                closeButton
            />

            <LinkOnLoginPage className={'mt-4'} text={t('forget_password')} path={`/${routesNames.AUTH}/${routesNames.RESTORE}`}/>

            <Button type="submit" className={'w-100 mb-3'}>
                {t('sign_in')}
            </Button>

            <LinkOnLoginPage text={t('register')} path={`/${routesNames.AUTH}/${routesNames.SIGNUP}`}/>

        </Form>
    );
};

export default LoginForm;
