import React, { useMemo } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {
    setModalForm,
} from "../../storage/global";
import PopUpForTry from "../forms/PopUpForTry";
import { makeGetRequest }  from "../../utils/api";
import { getCookie }       from "../../utils/cookie";

const FakeDoors = ({notificationBarIsOpen, closeNotificationBar}) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const headNotificationBarSettings = useSelector(state => state.global.headNotificationBarSettings)
    const fakeDoorsIcon = useSelector(state => state.global.fakeDoorsIcon)

    const text = useMemo(() => {
        const {
            notificationBarId,
            notificationBarText,
            notificationBarTextLink,
            cn_notificationBarText,
            cn_notificationBarTextLink,
        } = headNotificationBarSettings;
        const text = i18n.language === 'en' ? notificationBarText : cn_notificationBarText;
        const linktext = i18n.language === 'en' ? notificationBarTextLink : cn_notificationBarTextLink;
        let textBefore = text;
        let textAfter = '';
        if(text && linktext) {
            textBefore = text.slice(0, text.indexOf(linktext));
            const afterIndex = text.indexOf(linktext) + linktext.length;
            textAfter = text.slice(afterIndex)
        }
        const link = <i
            className={`cursor-pointer`}
            style={{ textDecoration: 'underline' }}
        >
            {linktext}
        </i>
        return <>
            {textBefore[textBefore.length-1] === ' ' ? textBefore.trim()+'\u00A0' : ''}
            {linktext && link}
            {textAfter[0] === ' ' ? '\u00A0'+textAfter.trim() : ''}
        </>
    }, [headNotificationBarSettings])

    const img = useMemo(() => {
        const {
            fakeDoorsIcon,
            notificationBarTextLink,
        } = headNotificationBarSettings;
        return headNotificationBarSettings && fakeDoorsIcon?.image_data ? <img
            style={{
                height: '56px'
            }}
            className={''}
            src={`data:${fakeDoorsIcon?.image_type};base64, ` + fakeDoorsIcon?.image_data}
            alt=""/> : null
    }, [headNotificationBarSettings])

    function openPopUp () {
        let fakeDoorUserId = getCookie('fakeDoorUserId');
        makeGetRequest('/api/misc/fakeDoorsUserAction', {
            fakeDoorId: headNotificationBarSettings.notificationBarId,
            fakeDoorUserId,
            fakeDoorUserAction: 'click',
            fakeDoorResource: 'cab',
        }, {})
        dispatch(setModalForm({
            title: '',
            form: <PopUpForTry/>,
            fullScreen: true,
            formContent: {
                classNames: 'rounded-0'
            },
            formBody: {
                classNames: 'p-0 modal-form-body-poll'
            },
            hideOut: false,
            styles: {
                zIndex: 2,
                background: 'rgba(0, 0, 0, 0.5)',
            },
            withoutClose: true
        }))
    }

    return (
        <div className=""
            style={{
                height: notificationBarIsOpen ? '68px' : 0, // 84
                background: 'linear-gradient(rgba(41, 93, 57, 0.8), rgba(85, 195, 119, 0.8))',
                display: 'flex',
                justifyContent: 'center',
                padding: notificationBarIsOpen ? '20px 20px' : '0 20px',
                boxSizing: 'border-box',
                transition: 'all 300ms ease-in-out',
            }}
            onClick={() => {
                openPopUp();
            }}
        >
            {notificationBarIsOpen && <div className={`wfc text-white d-flex fake-doors-text`} style={{lineheight: '28px'}}>
                {text}
                <div className="d-flex align-items-center h-100">
                    {img}
                </div>
            </div>}
            {notificationBarIsOpen && <div
                onClick={closeNotificationBar}
                className={`cursor-pointer`}
                style={{
                    position: 'absolute',
                    width: 'fit-content',
                    right: '20px',
                    top: '20px',
                    zIndex: 2,
                }}
                alt="close"
            >
                <img src="/assets/img/close-white.svg" alt="close"/>
            </div>}
        </div>
    );
};

export default FakeDoors;
