import React, {useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector }         from "react-redux";
import { useNavigate }                      from "react-router-dom";
import { useTranslation }                   from "react-i18next";
import moment                               from "moment";

import localStyles from "./styles.module.scss";
import { setSelectFromOut, setListPropertiesSelectFromOut, setModalForm } from "../../../../storage/global";
import PollForm from "../../../../components/forms/PollForm";
import { routesNames } from "../../../../constants";

const NotificationList: React.FC = ({close}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();

    const poll = useSelector(state => state.global.poll);
    const profileInfo = useSelector(state => state.global.profile);
    const balanceNotPaid = useSelector(state => state.global.balanceNotPaid);
    const lostList = useSelector(state => state.global.lost.list)

    const noPaidNotification = useMemo(() => {
        if (Number(balanceNotPaid.debt) > 0) return t('noPaidNotification')
        .replace(/(\$number)/, balanceNotPaid.count)
        .replace(/(\$amount)/, balanceNotPaid.debt)
        .replace(/(\$currency)/, profileInfo.currency);
        return '';
    }, [balanceNotPaid, profileInfo]);

    const pollDate = useMemo(() => {
        if (poll.id) {
            const d = new Date(+poll.update);
            const m = moment(d);
            const text = m.format('YYYY.MM.DD HH:mm');
            return text;
        }
        return '';
    }, [poll]);

    function openSentParsels () {
        dispatch(setListPropertiesSelectFromOut(balanceNotPaid.parcels));
        navigate(`/${routesNames.SENT_PARCELS}`);
        close();
    }

    function openLostParsels (TrackNumber) {
        navigate(`/${routesNames.UNIDENTIFIED_PARCELS}`);
        // dispatch(setSelectFromOut(TrackNumber));
        close();
    }

    function openPoll () {
        dispatch(setModalForm({
            title: '',
            form: <PollForm poll={poll}/>,
            fullScreen: true,
            formContent: {
                classNames: 'rounded-0'
            },
            formBody: {
                classNames: 'p-0 modal-form-body-poll'
            },
            hideOut: false,
            styles: {
                zIndex: 2,
                background: 'rgba(0, 0, 0, 0.5)',
            },
            // withoutClose: poll.require
            withoutClose: true
        }))
    }

    const Notification = t('Notification');
    const SurveyDated = t('survey_dated');

    return (
        <div className={localStyles.container}>
            <div className={`${localStyles.popupTitleContainer}`}>
                <p className={`${localStyles.popupTitle} btn-outline-primary-no-border`}>{Notification}</p>
            </div>
            {noPaidNotification ?
                <div className={`${localStyles.notifsListItem} cursor-pointer`} onClick={() => openSentParsels()}>
                    <p>{noPaidNotification}</p>
                </div>
                : null
            }
            {lostList.length ?
                // lostList.map( (el, i) => {
                //     return <div key={i} className={`${localStyles.notifsListItem} cursor-pointer`} onClick={() => openLostParsels(el.TrackNumber)}>
                //         <p>{t('lostParcelsNotification').replace(/(\$trackNumbers)/, el.TrackNumber)}</p>
                //     </div>
                // })
                <div className={`${localStyles.notifsListItem} cursor-pointer`} onClick={() => openLostParsels()}>
                    <p>{t('lostParcelsNotification')}</p>
                </div>
                : null
            }
            {pollDate ?
                <div className={`${localStyles.notifsListItem} cursor-pointer`} onClick={openPoll}>
                    <p>{i18n.language === 'en' ? `${SurveyDated} ${pollDate}` : `${pollDate} ${SurveyDated}`}</p>
                </div>
                : null
            }
        </div>

    )
}

export default NotificationList;


